import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footerbox'>
            <p>
                REM SPORT SUPPLEMENTS ® - TODOS LOS DERECHOS RESERVADOS
            </p>
        </div>
  );
}
export default Footer;